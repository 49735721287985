import { Browser } from '@mockingjay-io/shared-dependencies/src/types/entities/device';
import { BrowserLogoProps } from '@/components/bricks/BrowserLogo/BrowserLogo';

export type SupportedBrowserInfo = {
  label: string;
  icon: BrowserLogoProps['browser'];
};

export type SupportedBrowserExtendedInfo = SupportedBrowserInfo & {
  value: Browser;
};

export const supportedBrowsersMap: Record<Browser, SupportedBrowserInfo> = {
  [Browser.Chromium]: {
    label: 'Google Chrome',
    icon: 'chrome',
  },
  [Browser.Firefox]: {
    label: 'Mozilla Firefox',
    icon: 'firefox',
  },
  [Browser.Webkit]: {
    label: 'Apple Safari',
    icon: 'safari',
  },
  [Browser.Edge]: {
    label: 'Microsoft Edge',
    icon: 'edge',
  },
} as any;

export const supportedBrowsers: SupportedBrowserExtendedInfo[] = Object.keys(
  supportedBrowsersMap,
)
  .map(
    (browser: any) =>
      ({
        value: browser,
        ...supportedBrowsersMap[browser as Browser],
      }) as any,
  )
  .sort((a, b) => a.label.localeCompare(b.label));
