import { action, computed, makeObservable, observable, toJS } from 'mobx';
import config from '@/config';
import axios from 'axios';
import OAuth from '@/utils/oauth';
import tracker from '@/store/tracker';
import { clearQueryCaches } from '@/services';
import { BrowserStore } from '@/utils/storage';
// eslint-disable-next-line no-restricted-imports
import isPlainObject from 'lodash/isPlainObject';
import logger from '@/utils/logger';
import clipboard from './clipboard';
import { gql } from '@/__generated__';
import { executeQuery } from '@/urql';
import {
  TeamUserRoleEnum,
  UserQuery,
  UserTeamQuery,
} from '@/__generated__/graphql';
import { components } from '@/types/api';
import { makePersistable } from 'mobx-persist-store';
import { jwtDecode } from 'jwt-decode';
import { IdTokenPayload } from '@mockingjay-io/shared-dependencies/src/types/jwt';
import { isBrowser } from '@/utils/ssr';
import { showNotification } from '@mantine/notifications';
import Router from 'next/router';
import { digestMessage } from '@/utils/crypto';
import desktopService from '@/services/desktop';

export type AuthData = components['schemas']['OAuthClaimTicketResponse'];

const userQuery = gql(/* GraphQL */ `
  query User {
    users(limit: 1) {
      id
      name
      pictureUrl
      email
      lastLoginAt
      createdAt
      updatedAt
      globalRole
      teamsUsers(orderBy: { team: { name: ASC } }) {
        id
        team {
          id
          name
        }
      }
    }
  }
`);

const teamQuery = gql(/* GraphQL */ `
  query UserTeam($id: uuid!) {
    team: teamsByPk(id: $id) {
      __typename
      id
      name
      features
      retentionDays
      executionMinutesAvailable
      executionMinutesTotal
      emailDomain
      maxUsers
      createdAt
      updatedAt

      versions(orderBy: { version: DESC }, limit: 10) {
        __typename
        id
        name
        version
        source
        sourceVersion
        createdAt
        updatedAt
      }
    }
  }
`);

export type TeamInfo = NonNullable<UserTeamQuery['team']>;

const AUTH_LOCALSTORAGE_KEY = 'x-mj-auth';

export const roleWeights = {
  ADMIN: 100,
  BILLING_ADMIN: 90,
  TESTER: 50,
  VIEWER: 10,
};

export const oauth = new OAuth({
  clientId: config.OAUTH_CLIENT_ID,

  getRedirectUrl() {
    if (config.USE_API_REDIRECT_FOR_AUTH) {
      return `${config.API_BASE_URL}auth/redirect`;
    }
    return `${window.location.protocol}//${window.location.host}/login`;
  },

  getStartUrl() {
    return `${config.API_BASE_URL}oauth/start`;
  },
});

export type User = UserQuery['users'][0];

export function createAxiosInstance() {
  const instance = axios.create();
  instance.defaults.baseURL = config.API_BASE_URL;
  instance.interceptors.request.use(function (config) {
    // Auto serialize the filter param if present
    if (
      config.params &&
      config.params.filter &&
      isPlainObject(config.params.filter)
    ) {
      try {
        config.params.filter = JSON.stringify(config.params.filter);
      } catch (e) {
        logger.error(e, 'Failed to stringify filter');
      }
    }
    return config;
  });
  return instance;
}

export function reloadWithoutDynamicSegments() {
  // Router.pathname can contain dynamic segments like /flows/tests/[testId]/executions/[executionId]
  // We only want to take /flows/tests
  try {
    let reachedDynamic = false;
    window.location.href = Router.pathname.split('/').reduce((acc, part) => {
      if (part.includes('[') || reachedDynamic) {
        reachedDynamic = true;
        return acc;
      }
      return `${acc}/${part}`;
    });
  } catch (e) {
    logger.error(e, 'error reloading without dynamic segments');
    window.location.reload();
  }
}

class AuthStore {
  @observable token: string | null = null;
  @observable user: User | null = null;
  @observable userHash: string | null = null;
  @observable selectedTeam: TeamInfo | null = null;
  @observable selectedVersionId: string | null = null;
  @observable assumeTeamRole: TeamUserRoleEnum | null = null;
  @observable loading = false;

  private expiryTimeout: NodeJS.Timeout | null = null;
  private channel: BroadcastChannel | null = null;

  @computed
  get isAuthenticated() {
    return !!this.token && !!this.user && !this.loading;
  }

  @computed
  get teams() {
    return this.user?.teamsUsers.map((tu) => tu.team) || [];
  }

  @computed
  get selectedVersion() {
    return this.selectedTeam?.versions.find(
      (v) => v.id === this.selectedVersionId,
    );
  }

  @computed
  get axios() {
    const axiosInstance = createAxiosInstance();

    if (this.token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    }

    if (this.selectedTeam) {
      axiosInstance.defaults.headers.common['x-team-id'] = this.selectedTeam.id;
    }

    if (this.selectedVersionId) {
      axiosInstance.defaults.headers.common['x-version-id'] =
        this.selectedVersionId;
    }

    if (this.assumeTeamRole) {
      axiosInstance.defaults.headers.common['x-team-assume-role'] =
        this.assumeTeamRole;
    }

    return axiosInstance;
  }

  @computed
  get tokenPayload() {
    return this.token ? (jwtDecode(this.token) as IdTokenPayload) : null;
  }

  @computed
  get features() {
    const enabledFeatures: string[] = this.selectedTeam?.features || [];
    return {
      mailbox: enabledFeatures.includes('mailbox'),
      aiValidations: enabledFeatures.includes('ai-validations'),
      aiActions: enabledFeatures.includes('ai-actions'),
      aiBot: enabledFeatures.includes('ai-bot'),
      securityTesting: enabledFeatures.includes('security-testing'),
      mobileTesting: enabledFeatures.includes('mobile-testing'),
      taskManagement: enabledFeatures.includes('task-management'),
    };
  }

  setupTokenAutoExpiration() {
    if (this.expiryTimeout) {
      clearTimeout(this.expiryTimeout);
    }

    if (!this.tokenPayload) {
      return;
    }

    const { exp } = this.tokenPayload;
    if (!exp) {
      return;
    }

    const expirationTime = exp * 1000 - Date.now();
    if (expirationTime <= 120 * 1000) {
      return this.signOut();
    }

    this.expiryTimeout = setTimeout(() => {
      this.signOut();
    }, expirationTime);
  }

  @action
  async onAuthentication(session: AuthData) {
    try {
      this.loading = true;
      this.token = session.token;
      const userRes = await executeQuery(
        userQuery,
        {},
        {
          user: true,
          fetchOptions: {
            headers: {
              authorization: `Bearer ${session.token}`,
            },
          },
        },
      );
      this.user = userRes?.users[0] || null;
      if (!this.user || !this.token) {
        logger.error('no user or token found. deauthing');
        this.signOut();
        return;
      }

      try {
        this.userHash = await digestMessage(this.user.email);
      } catch (e) {
        logger.error(e, 'error creating user hash');
        this.userHash = null;
      }

      // If the user had previously assumed a role, we need to re-assume it if possible
      if (
        this.selectedTeam &&
        this.selectedTeam.id &&
        this.assumeTeamRole &&
        this.user.globalRole &&
        roleWeights[this.user.globalRole] >= roleWeights[this.assumeTeamRole]
      ) {
        await this.loadTeam(this.selectedTeam.id, this.assumeTeamRole);
      } else {
        // Else, we only check for a selection in one of the user's existing teams
        const selectedTeamId =
          (this.selectedTeam
            ? this.teams.find((t) => t.id === this.selectedTeam?.id)?.id
            : undefined) || this.teams[0]?.id;
        await this.loadTeam(selectedTeamId);
      }

      const plainUser = toJS(this.user);
      tracker.identify(plainUser as User);
      this.setupTokenAutoExpiration();
      this.channel?.postMessage('reload');
      if (this.features.mobileTesting) {
        desktopService.start();
      } else {
        desktopService.stop();
      }
    } finally {
      this.loading = false;
    }
  }

  @action
  async loadTeam(teamId?: string, assumeRole?: TeamUserRoleEnum) {
    teamId = teamId || this.selectedTeam?.id;
    assumeRole = assumeRole || this.assumeTeamRole || undefined;
    if (!teamId) {
      throw new Error('No team id provided');
    }
    const headers: Record<string, string> = {
      authorization: `Bearer ${this.token}`,
      'x-team-id': teamId,
    };
    if (assumeRole) {
      headers['x-team-assume-role'] = assumeRole;
    }
    const teamRes = await executeQuery(
      teamQuery,
      { id: teamId },
      {
        fetchOptions: {
          headers,
        },
      },
    );
    if (teamRes.team) {
      this.assumeTeamRole = assumeRole || null;
      this.selectedTeam = teamRes.team;
      this.selectedVersionId =
        (this.selectedVersionId
          ? this.selectedTeam.versions.find(
              (v) => v.id === this.selectedVersionId,
            )?.id
          : undefined) ||
        teamRes.team.versions[0]?.id ||
        null;
    }
  }

  @action
  async switchTeam(
    teamId: string,
    assumeRole?: TeamUserRoleEnum,
    redirectTo?: string,
  ) {
    const { selectedTeam, selectedVersionId, assumeTeamRole } = this;
    try {
      this.selectedVersionId = null;
      this.assumeTeamRole = null;
      await this.loadTeam(teamId, assumeRole);
      this.clearCaches();
      setTimeout(() => {
        this.channel?.postMessage('reload');
        if (redirectTo) {
          window.location.href = redirectTo;
        } else {
          reloadWithoutDynamicSegments();
        }
      }, 0);
    } catch (e) {
      logger.error(e, 'error switching team');
      this.selectedTeam = selectedTeam;
      this.assumeTeamRole = assumeTeamRole;
      this.selectedVersionId = selectedVersionId;
      showNotification({
        message: 'There was an error switching teams',
        color: 'red',
      });
    }
  }

  @action
  switchVersion(versionId: string) {
    this.selectedVersionId = versionId;
    this.clearCaches();
    setTimeout(() => {
      this.channel?.postMessage('reload');
      reloadWithoutDynamicSegments();
    }, 0);
  }

  @action
  async signIn(provider: string) {
    tracker.track('Sign in', { provider });
    this.clearCaches();
    await oauth.login(provider);
  }

  @action
  async signOut() {
    tracker.track('Sign out');
    this.user = null;
    this.userHash = null;
    this.token = null;
    tracker.reset();
    desktopService.stop();
    setTimeout(() => {
      this.clearCaches();
      this.channel?.postMessage('sign-out');
    }, 1000);
  }

  @action
  async clearCaches() {
    clearQueryCaches();
    clipboard.reset();
  }

  constructor() {
    makeObservable(this);

    if (!isBrowser()) {
      return;
    }
    this.channel = new BroadcastChannel(AUTH_LOCALSTORAGE_KEY);

    for (const oldKey of [
      'x-mj-auth-state',
      'x-mj-selected-team',
      'x-mj-selected-version',
    ]) {
      BrowserStore.remove(oldKey);
    }

    makePersistable(this, {
      name: AUTH_LOCALSTORAGE_KEY,
      storage: {
        setItem(key: string, value: any) {
          BrowserStore.put(key, value, false, false);
        },
        getItem<T>(key: string) {
          return BrowserStore.get(key, false) || null;
        },
        removeItem(key: string) {
          BrowserStore.remove(key);
        },
      },
      properties: [
        'token',
        'user',
        'selectedTeam',
        'selectedVersionId',
        'assumeTeamRole',
      ],
    })
      .then(() => {
        this.setupTokenAutoExpiration();
        try {
          if (this.isAuthenticated) {
            this.loadTeam().then(() => {
              if (this.features.mobileTesting) {
                desktopService.start();
              } else {
                desktopService.stop();
              }
            });
          }
        } catch (e) {
          logger.warn(e, 'error reloading team');
        }
      })
      .catch((e) => logger.error(e, 'error persisting storage'));

    this.channel.addEventListener('message', (event) => {
      if (event.source === self) {
        return;
      }
      if (event.data === 'reload') reloadWithoutDynamicSegments();
      else if (event.data === 'sign-out') this.signOut();
      else logger.error({ message: event.data }, 'unknown message');
    });
  }
}

const authState = new AuthStore();
export { authState as default };
