// Replacing the trailing slash if exists

const baseConfig = {
  authGuard: {
    publicPaths: ['/login', '/oauth'],
    anyPaths: ['/version', '/hatch/state'],
    globalRolePrefixes: ['/admin', '/global'],
    authorizedLandingPath: '/',
    loginPath: '/login',
  },
  ENV: process.env.NODE_ENV,
  BUILD_ID: process.env.NEXT_PUBLIC_BUILD_ID,
  BUILD_TIME: process.env.NEXT_PUBLIC_BUILD_TIME,
  HASH_KEY:
    process.env.NEXT_PUBLIC_HASH_KEY ||
    'bqdg7oCaWtbDsaBvYRibcJxKj2vqxLXUKuj3EjiQikisa8JVF3gujRmL24w3Ux4y',
  OAUTH_CLIENT_ID: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID || '',
  API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL || '',
  REGIONAL_API_BASE_URL: process.env.NEXT_PUBLIC_REGIONAL_API_BASE_URL || '',
  GRAPHQL_URL: process.env.NEXT_PUBLIC_GRAPHQL_URL || '',
  GRAPHQL_CDN_URL:
    process.env.NEXT_PUBLIC_GRAPHQL_CDN_URL ||
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    '',
  FRESH_CHAT_TOKEN: process.env.NEXT_PUBLIC_FRESH_CHAT_TOKEN || '',
  FRESH_CHAT_HOST: process.env.NEXT_PUBLIC_FRESH_CHAT_HOST || '',
  MIXPANEL_PROJECT_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN || '',
  CLARITY_PROJECT_ID: process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID || '',
  USE_API_REDIRECT_FOR_AUTH:
    process.env.NEXT_PUBLIC_USE_API_REDIRECT_FOR_AUTH === 'true',
  GRAPHIQL_ENABLED: process.env.NEXT_PUBLIC_GRAPHIQL_ENABLED === 'true',
  API_LRU_CACHE_SIZE: Number(
    process.env.NEXT_PUBLIC_API_LRU_CACHE_SIZE || '30',
  ),
  INBOUND_EMAIL_DOMAIN: process.env.NEXT_PUBLIC_INBOUND_EMAIL_DOMAIN || '',
  DEFAULT_COLOR_SCHEME: (process.env.NEXT_PUBLIC_DEFAULT_COLOR_SCHEME ||
    'light') as 'light' | 'dark',
  GITHUB_APP_INSTALLATION_URL:
    process.env.NEXT_PUBLIC_GITHUB_APP_INSTALLATION_URL ||
    'https://github.com/apps/mockingjay-io/installations/new',
  DESKTOP_CONTROLLER_URL:
    process.env.NEXT_PUBLIC_DESKTOP_CONTROLLER_URL ||
    'ws://localhost/api/v1/31202908-c016-4ebd-9e9d-35919bd118ad/controller',
  DESKTOP_CONTROLLER_PORTS:
    process.env.NEXT_PUBLIC_DESKTOP_CONTROLLER_PORTS?.split(',')?.map((val) =>
      parseInt(val),
    ) || [49152, 50000, 50505, 51000, 51500, 52000, 52525, 53000, 54321, 55555],
};

const config = {
  ...baseConfig,
  DEBUG: baseConfig.ENV === 'development',
  WS_API_BASE_URL: baseConfig.API_BASE_URL.replace(/^http/, 'ws'),
  REGIONAL_WS_API_BASE_URL: baseConfig.REGIONAL_API_BASE_URL.replace(
    /^http/,
    'ws',
  ),
};

export default config;
