import NotFoundPage from '@/pages/404';
import InternalErrorPage from '@/pages/500';
import appShellState from '@/store/app-shell';
import logger from '@/utils/logger';
import { useEffect } from 'react';

export class NotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

type ErrorHandlerProps = {
  error: Error;
  reset?: () => void;
};

function ErrorHandler({ error }: ErrorHandlerProps) {
  useEffect(() => {
    appShellState.toggleErrorMode(true);
    return () => {
      appShellState.toggleErrorMode(false);
    };
  }, []);

  if (error instanceof NotFoundError) {
    return <NotFoundPage />;
  }

  logger.error({ error, href: location.href }, 'Unhandled client error');
  return <InternalErrorPage />;
}

export default ErrorHandler;
